import React from "react";
import { Form } from "react-bootstrap";
import { useField, ErrorMessage } from "formik";

const InputBox = ({ id, label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Form.Group className="mb-3" controlId={id}>
      {label && <Form.Label className="font-semi-bold">{label}</Form.Label>}
      <Form.Control
        autoComplete="off"
        isInvalid={meta.touched && meta.error}
        {...field}
        {...props}
      />
      {meta.touched && meta.error && (
        <Form.Control.Feedback className="font-medium text-sm" type="invalid">
          <ErrorMessage component="div" name={props.name} />
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default InputBox;
