import React, { Fragment, useState } from "react";
import { Container, Row, Col, Card, Button, Form as BsForm } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputBox from "./InputBox";
import axios from "axios";
import toast from "react-hot-toast";
import Spinner from "./spinner";

const Home = () => {
  const [data, setData] = useState({});
  const [inputDisable, setInputDisable] = useState(false);

  const validationSchema = Yup.object({
    key: Yup.string().min(36).max(36).required().label("Redemption code"),
  });

  return (
    <div className="bg-light">
      <Container className="vh-100">
        <Row className="h-100 align-items-center">
          <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} sm={{ span: 8, offset: 2 }}>
            <Card className="shadow-sm">
              <Card.Header className="fw-bold">Course Hero Unlock</Card.Header>
              <Card.Body>
                <Formik
                  initialValues={{ key: "" }}
                  validationSchema={validationSchema}
                  onSubmit={async (values, helpers) => {
                    return await axios({
                      method: "GET",
                      url: `https://api.coursehero.ga/coursehero/getstatus?serialKey=${values.key}`,
                    })
                      .then((res) => {
                        console.log(res.data);
                        setData(res.data);
                        setInputDisable(true);
                      })
                      .catch((err) => {
                        console.log(err.response.data);
                        helpers.setFieldError("key", err.response.data.error);
                        setInputDisable(false);
                      });
                  }}
                >
                  {({
                    isSubmitting,
                    isValid,
                    dirty,
                    errors,
                    values,
                    setFieldValue,
                    setFieldError,
                  }) => (
                    <Form>
                      <InputBox
                        id="key"
                        name="key"
                        label="Redemption code:"
                        disabled={inputDisable}
                      />

                      {data && data.redeemed === true ? (
                        <RedeemTrue
                          setData={setData}
                          setFieldValue={setFieldValue}
                          setInputDisable={setInputDisable}
                          setFieldError={setFieldError}
                          requestedUrl={data?.requestedUrl}
                          unlockedUrl={data?.unlockedUrl}
                          disabled={errors.key ? false : true && data}
                        />
                      ) : null}

                      {!inputDisable ? (
                        <Row>
                          <Col md={4}>
                            <div className="d-grid">
                              <Button
                                onClick={() => {
                                  setData({});
                                  setInputDisable(false);
                                  setFieldValue("key", "");
                                  setFieldError("key", "");
                                }}
                              >
                                Reset
                              </Button>
                            </div>
                          </Col>
                          <Col md={8}>
                            <div className="d-grid">
                              <Button
                                className="float-end"
                                disabled={!(dirty && isValid) || isSubmitting}
                                type="submit"
                              >
                                {isSubmitting ? <Spinner /> : "Submit"}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        data &&
                        data.requestedUrl === null && (
                          <UnlockUrl
                            keyInput={values.key}
                            setData={setData}
                            setInputDisable={setInputDisable}
                            setFieldValue={setFieldValue}
                          />
                        )
                      )}
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;

const RedeemTrue = ({
  requestedUrl,
  unlockedUrl,
  setData,
  setInputDisable,
  setFieldValue,
  setFieldError,
}) => {
  return (
    <Fragment>
      <BsForm.Group className="mb-3">
        <BsForm.Label>Requested URL</BsForm.Label>
        <BsForm.Control
          as="textarea"
          rows={4}
          style={{ resize: "none" }}
          defaultValue={requestedUrl}
          disabled
        />
      </BsForm.Group>
      <Row>
        <Col md={4}>
          <div className="d-grid">
            <Button
              onClick={() => {
                // setData({});
                // setInputDisable(false);
                // setFieldValue("key", "");
                // setFieldError("key", "");
                window.location.reload();
              }}
            >
              Reset
            </Button>
          </div>
        </Col>
        <Col md={8}>
          <div className="d-grid">
            <Button className="float-end" href={unlockedUrl} target="_blank">
              View
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

const UnlockUrl = ({ keyInput, setData, setInputDisable, setFieldValue }) => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [data2, setData2] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios({
      method: "POST",
      url: `https://api.coursehero.ga/coursehero/unlock`,
      data: {
        SerielKey: keyInput,
        RequestedUrl: value,
      },
    })
      .then((res) => {
        setData2(res.data);

        setLoading(false);
      })
      .catch((err) => {
        // console.log(err.response.data);
        toast.error(err.response.data.error);
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {data2 && data2.redeemed === true ? (
        <RedeemTrue requestedUrl={data2?.requestedUrl} unlockedUrl={data2?.unlockedUrl} />
      ) : (
        <Fragment>
          <BsForm.Group className="mb-3">
            <BsForm.Label>Request URL</BsForm.Label>
            <BsForm.Control
              as="textarea"
              rows={4}
              style={{ resize: "none" }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </BsForm.Group>
          <Row>
            <Col md={4}>
              <div className="d-grid">
                <Button
                  onClick={() => {
                    setData2({});
                    setData({});
                    setInputDisable(false);
                    setFieldValue("key", "");
                    setValue("");
                  }}
                >
                  Reset
                </Button>
              </div>
            </Col>
            <Col md={8}>
              <div className="d-grid">
                <Button className="float-end" disabled={!value || loading} onClick={handleSubmit}>
                  {loading ? <Spinner /> : "Unlock"}
                </Button>
              </div>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};
